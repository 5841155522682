const ExpData = [
  {
    level: "200",
    "익스트림 성장의 비약": "25.877",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "201",
    "익스트림 성장의 비약": "23.105",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "202",
    "익스트림 성장의 비약": "20.629",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "203",
    "익스트림 성장의 비약": "18.419",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "204",
    "익스트림 성장의 비약": "16.445",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "205",
    "익스트림 성장의 비약": "14.683",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "206",
    "익스트림 성장의 비약": "13.110",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "207",
    "익스트림 성장의 비약": "11.706",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "208",
    "익스트림 성장의 비약": "10.451",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "209",
    "익스트림 성장의 비약": "9.332",
    "성장의 비약 (200~209)": "100.000",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "210",
    "익스트림 성장의 비약": "7.178",
    "성장의 비약 (200~209)": "76.923",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "211",
    "익스트림 성장의 비약": "6.467",
    "성장의 비약 (200~209)": "69.300",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "212",
    "익스트림 성장의 비약": "5.826",
    "성장의 비약 (200~209)": "62.432",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "213",
    "익스트림 성장의 비약": "5.249",
    "성장의 비약 (200~209)": "56.245",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "214",
    "익스트림 성장의 비약": "4.728",
    "성장의 비약 (200~209)": "50.672",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "215",
    "익스트림 성장의 비약": "3.637",
    "성장의 비약 (200~209)": "38.978",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "216",
    "익스트림 성장의 비약": "3.337",
    "성장의 비약 (200~209)": "35.760",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "216",
    "익스트림 성장의 비약": "3.337",
    "성장의 비약 (200~209)": "35.760",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "217",
    "익스트림 성장의 비약": "3.061",
    "성장의 비약 (200~209)": "32.807",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "218",
    "익스트림 성장의 비약": "2.809",
    "성장의 비약 (200~209)": "30.098",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "219",
    "익스트림 성장의 비약": "2.577",
    "성장의 비약 (200~209)": "27.613",
    "성장의 비약 (200~219)": "100.000",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "220",
    "익스트림 성장의 비약": "1.982",
    "성장의 비약 (200~209)": "21.241",
    "성장의 비약 (200~219)": "76.923",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "221",
    "익스트림 성장의 비약": "1.852",
    "성장의 비약 (200~209)": "19.851",
    "성장의 비약 (200~219)": "71.891",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "222",
    "익스트림 성장의 비약": "1.731",
    "성장의 비약 (200~209)": "18.553",
    "성장의 비약 (200~219)": "67.188",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "223",
    "익스트림 성장의 비약": "1.618",
    "성장의 비약 (200~209)": "17.339",
    "성장의 비약 (200~219)": "62.792",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "224",
    "익스트림 성장의 비약": "1.512",
    "성장의 비약 (200~209)": "16.205",
    "성장의 비약 (200~219)": "58.684",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "225",
    "익스트림 성장의 비약": "1.163",
    "성장의 비약 (200~209)": "12.465",
    "성장의 비약 (200~219)": "45.142",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "226",
    "익스트림 성장의 비약": "1.087",
    "성장의 비약 (200~209)": "11.650",
    "성장의 비약 (200~219)": "42.189",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "227",
    "익스트림 성장의 비약": "1.016",
    "성장의 비약 (200~209)": "10.887",
    "성장의 비약 (200~219)": "39.429",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "228",
    "익스트림 성장의 비약": "0.950",
    "성장의 비약 (200~209)": "10.175",
    "성장의 비약 (200~219)": "36.849",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "229",
    "익스트림 성장의 비약": "0.887",
    "성장의 비약 (200~209)": "9.510",
    "성장의 비약 (200~219)": "34.438",
    "성장의 비약 (200~229)": "100.000",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "230",
    "익스트림 성장의 비약": "0.683",
    "성장의 비약 (200~209)": "7.315",
    "성장의 비약 (200~219)": "26.491",
    "성장의 비약 (200~229)": "76.923",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "231",
    "익스트림 성장의 비약": "0.663",
    "성장의 비약 (200~209)": "7.102",
    "성장의 비약 (200~219)": "25.719",
    "성장의 비약 (200~229)": "74.683",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "232",
    "익스트림 성장의 비약": "0.643",
    "성장의 비약 (200~209)": "6.895",
    "성장의 비약 (200~219)": "24.970",
    "성장의 비약 (200~229)": "72.507",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "233",
    "익스트림 성장의 비약": "0.625",
    "성장의 비약 (200~209)": "6.694",
    "성장의 비약 (200~219)": "24.243",
    "성장의 비약 (200~229)": "70.396",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "234",
    "익스트림 성장의 비약": "0.606",
    "성장의 비약 (200~209)": "6.499",
    "성장의 비약 (200~219)": "23.537",
    "성장의 비약 (200~229)": "68.345",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "235",
    "익스트림 성장의 비약": "0.467",
    "성장의 비약 (200~209)": "4.999",
    "성장의 비약 (200~219)": "18.105",
    "성장의 비약 (200~229)": "52.573",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "236",
    "익스트림 성장의 비약": "0.453",
    "성장의 비약 (200~209)": "4.854",
    "성장의 비약 (200~219)": "17.578",
    "성장의 비약 (200~229)": "51.042",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "237",
    "익스트림 성장의 비약": "0.440",
    "성장의 비약 (200~209)": "4.712",
    "성장의 비약 (200~219)": "17.066",
    "성장의 비약 (200~229)": "49.555",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "238",
    "익스트림 성장의 비약": "0.427",
    "성장의 비약 (200~209)": "4.575",
    "성장의 비약 (200~219)": "16.569",
    "성장의 비약 (200~229)": "48.112",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "239",
    "익스트림 성장의 비약": "0.415",
    "성장의 비약 (200~209)": "4.442",
    "성장의 비약 (200~219)": "16.086",
    "성장의 비약 (200~229)": "46.711",
    "태풍 성장의 비약": "100.000",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "240",
    "익스트림 성장의 비약": "0.319",
    "성장의 비약 (200~209)": "3.417",
    "성장의 비약 (200~219)": "12.374",
    "성장의 비약 (200~229)": "35.931",
    "태풍 성장의 비약": "76.923",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "241",
    "익스트림 성장의 비약": "0.310",
    "성장의 비약 (200~209)": "3.317",
    "성장의 비약 (200~219)": "12.014",
    "성장의 비약 (200~229)": "34.885",
    "태풍 성장의 비약": "74.683",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "242",
    "익스트림 성장의 비약": "0.301",
    "성장의 비약 (200~209)": "3.221",
    "성장의 비약 (200~219)": "11.664",
    "성장의 비약 (200~229)": "33.869",
    "태풍 성장의 비약": "72.507",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "243",
    "익스트림 성장의 비약": "0.292",
    "성장의 비약 (200~209)": "3.127",
    "성장의 비약 (200~219)": "11.324",
    "성장의 비약 (200~229)": "32.882",
    "태풍 성장의 비약": "70.396",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "244",
    "익스트림 성장의 비약": "0.283",
    "성장의 비약 (200~209)": "3.036",
    "성장의 비약 (200~219)": "10.994",
    "성장의 비약 (200~229)": "31.924",
    "태풍 성장의 비약": "68.345",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "245",
    "익스트림 성장의 비약": "0.218",
    "성장의 비약 (200~209)": "2.335",
    "성장의 비약 (200~219)": "8.457",
    "성장의 비약 (200~229)": "24.557",
    "태풍 성장의 비약": "52.573",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "246",
    "익스트림 성장의 비약": "0.212",
    "성장의 비약 (200~209)": "2.267",
    "성장의 비약 (200~219)": "8.211",
    "성장의 비약 (200~229)": "23.842",
    "태풍 성장의 비약": "51.042",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "247",
    "익스트림 성장의 비약": "0.205",
    "성장의 비약 (200~209)": "2.201",
    "성장의 비약 (200~219)": "7.972",
    "성장의 비약 (200~229)": "23.148",
    "태풍 성장의 비약": "49.555",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "248",
    "익스트림 성장의 비약": "0.199",
    "성장의 비약 (200~209)": "2.137",
    "성장의 비약 (200~219)": "7.739",
    "성장의 비약 (200~229)": "22.473",
    "태풍 성장의 비약": "48.112",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "249",
    "익스트림 성장의 비약": "0.194",
    "성장의 비약 (200~209)": "2.075",
    "성장의 비약 (200~219)": "7.514",
    "성장의 비약 (200~229)": "21.819",
    "태풍 성장의 비약": "46.711",
    "극한 성장의 비약": "100.000",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "250",
    "익스트림 성장의 비약": "0.129",
    "성장의 비약 (200~209)": "1.383",
    "성장의 비약 (200~219)": "5.009",
    "성장의 비약 (200~229)": "14.546",
    "태풍 성장의 비약": "31.140",
    "극한 성장의 비약": "66.667",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "251",
    "익스트림 성장의 비약": "0.125",
    "성장의 비약 (200~209)": "1.343",
    "성장의 비약 (200~219)": "4.863",
    "성장의 비약 (200~229)": "14.122",
    "태풍 성장의 비약": "30.233",
    "극한 성장의 비약": "64.725",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "252",
    "익스트림 성장의 비약": "0.122",
    "성장의 비약 (200~209)": "1.304",
    "성장의 비약 (200~219)": "4.722",
    "성장의 비약 (200~229)": "13.711",
    "태풍 성장의 비약": "29.353",
    "극한 성장의 비약": "62.840",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "253",
    "익스트림 성장의 비약": "0.118",
    "성장의 비약 (200~209)": "1.266",
    "성장의 비약 (200~219)": "4.584",
    "성장의 비약 (200~229)": "13.312",
    "태풍 성장의 비약": "28.498",
    "극한 성장의 비약": "61.009",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "254",
    "익스트림 성장의 비약": "0.115",
    "성장의 비약 (200~209)": "1.229",
    "성장의 비약 (200~219)": "4.451",
    "성장의 비약 (200~229)": "12.924",
    "태풍 성장의 비약": "27.668",
    "극한 성장의 비약": "59.232",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "255",
    "익스트림 성장의 비약": "0.111",
    "성장의 비약 (200~209)": "1.193",
    "성장의 비약 (200~219)": "4.321",
    "성장의 비약 (200~229)": "12.547",
    "태풍 성장의 비약": "26.862",
    "극한 성장의 비약": "57.507",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "256",
    "익스트림 성장의 비약": "0.108",
    "성장의 비약 (200~209)": "1.158",
    "성장의 비약 (200~219)": "4.195",
    "성장의 비약 (200~229)": "12.182",
    "태풍 성장의 비약": "26.080",
    "극한 성장의 비약": "55.832",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "257",
    "익스트림 성장의 비약": "0.105",
    "성장의 비약 (200~209)": "1.125",
    "성장의 비약 (200~219)": "4.073",
    "성장의 비약 (200~229)": "11.827",
    "태풍 성장의 비약": "25.320",
    "극한 성장의 비약": "54.206",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "258",
    "익스트림 성장의 비약": "0.102",
    "성장의 비약 (200~209)": "1.092",
    "성장의 비약 (200~219)": "3.954",
    "성장의 비약 (200~229)": "11.483",
    "태풍 성장의 비약": "24.583",
    "극한 성장의 비약": "52.627",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "259",
    "익스트림 성장의 비약": "0.099",
    "성장의 비약 (200~209)": "1.060",
    "성장의 비약 (200~219)": "3.839",
    "성장의 비약 (200~229)": "11.148",
    "태풍 성장의 비약": "23.867",
    "극한 성장의 비약": "51.094",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "260",
    "익스트림 성장의 비약": "0.033",
    "성장의 비약 (200~209)": "0.353",
    "성장의 비약 (200~219)": "1.280",
    "성장의 비약 (200~229)": "3.716",
    "태풍 성장의 비약": "7.956",
    "극한 성장의 비약": "17.031",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "261",
    "익스트림 성장의 비약": "0.033",
    "성장의 비약 (200~209)": "0.350",
    "성장의 비약 (200~219)": "1.267",
    "성장의 비약 (200~229)": "3.679",
    "태풍 성장의 비약": "7.877",
    "극한 성장의 비약": "16.863",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "262",
    "익스트림 성장의 비약": "0.032",
    "성장의 비약 (200~209)": "0.346",
    "성장의 비약 (200~219)": "1.255",
    "성장의 비약 (200~229)": "3.643",
    "태풍 성장의 비약": "7.799",
    "극한 성장의 비약": "16.696",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "263",
    "익스트림 성장의 비약": "0.032",
    "성장의 비약 (200~209)": "0.343",
    "성장의 비약 (200~219)": "1.242",
    "성장의 비약 (200~229)": "3.607",
    "태풍 성장의 비약": "7.722",
    "극한 성장의 비약": "16.531",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "264",
    "익스트림 성장의 비약": "0.032",
    "성장의 비약 (200~209)": "0.340",
    "성장의 비약 (200~219)": "1.230",
    "성장의 비약 (200~229)": "3.571",
    "태풍 성장의 비약": "7.645",
    "극한 성장의 비약": "16.367",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "265",
    "익스트림 성장의 비약": "0.024",
    "성장의 비약 (200~209)": "0.261",
    "성장의 비약 (200~219)": "0.946",
    "성장의 비약 (200~229)": "2.747",
    "태풍 성장의 비약": "5.881",
    "극한 성장의 비약": "12.590",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "266",
    "익스트림 성장의 비약": "0.024",
    "성장의 비약 (200~209)": "0.259",
    "성장의 비약 (200~219)": "0.937",
    "성장의 비약 (200~229)": "2.720",
    "태풍 성장의 비약": "5.823",
    "극한 성장의 비약": "12.465",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "267",
    "익스트림 성장의 비약": "0.024",
    "성장의 비약 (200~209)": "0.256",
    "성장의 비약 (200~219)": "0.927",
    "성장의 비약 (200~229)": "2.693",
    "태풍 성장의 비약": "5.765",
    "극한 성장의 비약": "12.342",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "268",
    "익스트림 성장의 비약": "0.024",
    "성장의 비약 (200~209)": "0.254",
    "성장의 비약 (200~219)": "0.918",
    "성장의 비약 (200~229)": "2.666",
    "태풍 성장의 비약": "5.708",
    "극한 성장의 비약": "12.220",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "269",
    "익스트림 성장의 비약": "0.023",
    "성장의 비약 (200~209)": "0.251",
    "성장의 비약 (200~219)": "0.909",
    "성장의 비약 (200~229)": "2.640",
    "태풍 성장의 비약": "5.651",
    "극한 성장의 비약": "12.099",
    "초월 성장의 비약": "100.000 ",
  },
  {
    level: "270",
    "익스트림 성장의 비약": "0.011",
    "성장의 비약 (200~209)": "0.113",
    "성장의 비약 (200~219)": "0.410",
    "성장의 비약 (200~229)": "1.189",
    "태풍 성장의 비약": "2.546",
    "극한 성장의 비약": "5.450",
    "초월 성장의 비약": "45.045 ",
  },
  {
    level: "271",
    "익스트림 성장의 비약": "0.010",
    "성장의 비약 (200~209)": "0.112",
    "성장의 비약 (200~219)": "0.405",
    "성장의 비약 (200~229)": "1.177",
    "태풍 성장의 비약": "2.520",
    "극한 성장의 비약": "5.396",
    "초월 성장의 비약": "44.599 ",
  },
  {
    level: "272",
    "익스트림 성장의 비약": "0.010",
    "성장의 비약 (200~209)": "0.111",
    "성장의 비약 (200~219)": "0.401",
    "성장의 비약 (200~229)": "1.166",
    "태풍 성장의 비약": "2.496",
    "극한 성장의 비약": "5.342",
    "초월 성장의 비약": "44.157 ",
  },
  {
    level: "273",
    "익스트림 성장의 비약": "0.010",
    "성장의 비약 (200~209)": "0.110",
    "성장의 비약 (200~219)": "0.397",
    "성장의 비약 (200~229)": "1.154",
    "태풍 성장의 비약": "2.471",
    "극한 성장의 비약": "5.290",
    "초월 성장의 비약": "43.720 ",
  },
  {
    level: "274",
    "익스트림 성장의 비약": "0.010",
    "성장의 비약 (200~209)": "0.109",
    "성장의 비약 (200~219)": "0.394",
    "성장의 비약 (200~229)": "1.143",
    "태풍 성장의 비약": "2.446",
    "극한 성장의 비약": "5.237",
    "초월 성장의 비약": "43.287 ",
  },
  {
    level: "275",
    "익스트림 성장의 비약": "0.005",
    "성장의 비약 (200~209)": "0.054",
    "성장의 비약 (200~219)": "0.195",
    "성장의 비약 (200~229)": "0.566",
    "태풍 성장의 비약": "1.211",
    "극한 성장의 비약": "2.593",
    "초월 성장의 비약": "21.429 ",
  },
  {
    level: "276",
    "익스트림 성장의 비약": "0.005",
    "성장의 비약 (200~209)": "0.049",
    "성장의 비약 (200~219)": "0.177",
    "성장의 비약 (200~229)": "0.514",
    "태풍 성장의 비약": "1.101",
    "극한 성장의 비약": "2.357",
    "초월 성장의 비약": "19.481 ",
  },
  {
    level: "277",
    "익스트림 성장의 비약": "0.004",
    "성장의 비약 (200~209)": "0.044",
    "성장의 비약 (200~219)": "0.161",
    "성장의 비약 (200~229)": "0.468",
    "태풍 성장의 비약": "1.001",
    "극한 성장의 비약": "2.143",
    "초월 성장의 비약": "17.710 ",
  },
  {
    level: "278",
    "익스트림 성장의 비약": "0.004",
    "성장의 비약 (200~209)": "0.040",
    "성장의 비약 (200~219)": "0.146",
    "성장의 비약 (200~229)": "0.425",
    "태풍 성장의 비약": "0.910",
    "극한 성장의 비약": "1.948",
    "초월 성장의 비약": "16.100 ",
  },
  {
    level: "279",
    "익스트림 성장의 비약": "0.003",
    "성장의 비약 (200~209)": "0.037",
    "성장의 비약 (200~219)": "0.133",
    "성장의 비약 (200~229)": "0.386",
    "태풍 성장의 비약": "0.827",
    "극한 성장의 비약": "1.771",
    "초월 성장의 비약": "14.637 ",
  },
  {
    level: "280",
    "익스트림 성장의 비약": "0.002",
    "성장의 비약 (200~209)": "0.018",
    "성장의 비약 (200~219)": "0.066",
    "성장의 비약 (200~229)": "0.191",
    "태풍 성장의 비약": "0.409",
    "극한 성장의 비약": "0.877",
    "초월 성장의 비약": "7.246 ",
  },
  {
    level: "281",
    "익스트림 성장의 비약": "0.002",
    "성장의 비약 (200~209)": "0.017",
    "성장의 비약 (200~219)": "0.060",
    "성장의 비약 (200~229)": "0.174",
    "태풍 성장의 비약": "0.372",
    "극한 성장의 비약": "0.797",
    "초월 성장의 비약": "6.587 ",
  },
  {
    level: "282",
    "익스트림 성장의 비약": "0.001",
    "성장의 비약 (200~209)": "0.015",
    "성장의 비약 (200~219)": "0.054",
    "성장의 비약 (200~229)": "0.158",
    "태풍 성장의 비약": "0.338",
    "극한 성장의 비약": "0.725",
    "초월 성장의 비약": "5.988 ",
  },
  {
    level: "283",
    "익스트림 성장의 비약": "0.001",
    "성장의 비약 (200~209)": "0.014",
    "성장의 비약 (200~219)": "0.049",
    "성장의 비약 (200~229)": "0.144",
    "태풍 성장의 비약": "0.308",
    "극한 성장의 비약": "0.659",
    "초월 성장의 비약": "5.444 ",
  },
  {
    level: "284",
    "익스트림 성장의 비약": "0.001",
    "성장의 비약 (200~209)": "0.012",
    "성장의 비약 (200~219)": "0.045",
    "성장의 비약 (200~229)": "0.131",
    "태풍 성장의 비약": "0.280",
    "극한 성장의 비약": "0.599",
    "초월 성장의 비약": "4.949 ",
  },
  {
    level: "285",
    "익스트림 성장의 비약": "0.001",
    "성장의 비약 (200~209)": "0.006",
    "성장의 비약 (200~219)": "0.022",
    "성장의 비약 (200~229)": "0.065",
    "태풍 성장의 비약": "0.138",
    "극한 성장의 비약": "0.296",
    "초월 성장의 비약": "2.450 ",
  },
  {
    level: "286",
    "익스트림 성장의 비약": "0.001",
    "성장의 비약 (200~209)": "0.006",
    "성장의 비약 (200~219)": "0.020",
    "성장의 비약 (200~229)": "0.059",
    "태풍 성장의 비약": "0.126",
    "극한 성장의 비약": "0.269",
    "초월 성장의 비약": "2.227 ",
  },
  {
    level: "287",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.005",
    "성장의 비약 (200~219)": "0.018",
    "성장의 비약 (200~229)": "0.053",
    "태풍 성장의 비약": "0.114",
    "극한 성장의 비약": "0.245",
    "초월 성장의 비약": "2.025 ",
  },
  {
    level: "288",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.005",
    "성장의 비약 (200~219)": "0.017",
    "성장의 비약 (200~229)": "0.049",
    "태풍 성장의 비약": "0.104",
    "극한 성장의 비약": "0.223",
    "초월 성장의 비약": "1.841 ",
  },
  {
    level: "289",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.004",
    "성장의 비약 (200~219)": "0.015",
    "성장의 비약 (200~229)": "0.044",
    "태풍 성장의 비약": "0.095",
    "극한 성장의 비약": "0.202",
    "초월 성장의 비약": "1.673 ",
  },
  {
    level: "290",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.002",
    "성장의 비약 (200~219)": "0.008",
    "성장의 비약 (200~229)": "0.022",
    "태풍 성장의 비약": "0.047",
    "극한 성장의 비약": "0.100",
    "초월 성장의 비약": "0.828 ",
  },
  {
    level: "291",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.002",
    "성장의 비약 (200~219)": "0.007",
    "성장의 비약 (200~229)": "0.020",
    "태풍 성장의 비약": "0.043",
    "극한 성장의 비약": "0.091",
    "초월 성장의 비약": "0.753 ",
  },
  {
    level: "292",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.002",
    "성장의 비약 (200~219)": "0.006",
    "성장의 비약 (200~229)": "0.018",
    "태풍 성장의 비약": "0.039",
    "극한 성장의 비약": "0.083",
    "초월 성장의 비약": "0.685 ",
  },
  {
    level: "293",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.002",
    "성장의 비약 (200~219)": "0.006",
    "성장의 비약 (200~229)": "0.016",
    "태풍 성장의 비약": "0.035",
    "극한 성장의 비약": "0.075",
    "초월 성장의 비약": "0.622 ",
  },
  {
    level: "294",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.001",
    "성장의 비약 (200~219)": "0.005",
    "성장의 비약 (200~229)": "0.015",
    "태풍 성장의 비약": "0.032",
    "극한 성장의 비약": "0.068",
    "초월 성장의 비약": "0.566 ",
  },
  {
    level: "295",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.001",
    "성장의 비약 (200~219)": "0.003",
    "성장의 비약 (200~229)": "0.007",
    "태풍 성장의 비약": "0.016",
    "극한 성장의 비약": "0.034",
    "초월 성장의 비약": "0.280 ",
  },
  {
    level: "296",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.001",
    "성장의 비약 (200~219)": "0.002",
    "성장의 비약 (200~229)": "0.007",
    "태풍 성장의 비약": "0.014",
    "극한 성장의 비약": "0.031",
    "초월 성장의 비약": "0.255 ",
  },
  {
    level: "297",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.001",
    "성장의 비약 (200~219)": "0.002",
    "성장의 비약 (200~229)": "0.006",
    "태풍 성장의 비약": "0.013",
    "극한 성장의 비약": "0.028",
    "초월 성장의 비약": "0.231 ",
  },
  {
    level: "298",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.001",
    "성장의 비약 (200~219)": "0.002",
    "성장의 비약 (200~229)": "0.006",
    "태풍 성장의 비약": "0.012",
    "극한 성장의 비약": "0.025",
    "초월 성장의 비약": "0.210 ",
  },
  {
    level: "299",
    "익스트림 성장의 비약": "0.000",
    "성장의 비약 (200~209)": "0.000",
    "성장의 비약 (200~219)": "0.001",
    "성장의 비약 (200~229)": "0.004",
    "태풍 성장의 비약": "0.008",
    "극한 성장의 비약": "0.017",
    "초월 성장의 비약": "0.140",
  },
];

export default ExpData;
