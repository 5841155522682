import Anthill_BG from "../../../assets/InfoBgImg/black/Anthill_BG.jpg"
import Arcana_BG from "../../../assets/InfoBgImg/black/Arcana_BG.jpg" 
import El_Nath_BG from "../../../assets/InfoBgImg/black/El_Nath_BG.jpg" 
import Kerning_City_BG from "../../../assets/InfoBgImg/black/Kerning_City_BG.jpg" 
import Limen_BG from "../../../assets/InfoBgImg/black/Limen_BG.jpg" 
import Magatia_BG from "../../../assets/InfoBgImg/black/Magatia_BG.jpg" 
import Papulatus_BG from "../../../assets/InfoBgImg/black/Papulatus_BG.jpg" 
import Sword_Grave_BG from "../../../assets/InfoBgImg/black/Sword_Grave_BG.jpg" 


import Aquarium_BG from "../../../assets/InfoBgImg/white/Aquarium_BG.jpg" 
import Esfera_BG from "../../../assets/InfoBgImg/white/Esfera_BG.jpg" 
import Henesys_BG from "../../../assets/InfoBgImg/white/Henesys_BG.jpg" 
import Lien_BG from "../../../assets/InfoBgImg/white/Lien_BG.jpg" 
import Lith_Harbor_BG from "../../../assets/InfoBgImg/white/Lith_Harbor_BG.jpg" 
import Ludibrium_BG from "../../../assets/InfoBgImg/white/Ludibrium_BG.jpg" 
import Mu_Lung_BG from "../../../assets/InfoBgImg/white/Mu_Lung_BG.jpg" 
import Orbis_BG from "../../../assets/InfoBgImg/white/Orbis_BG.jpg" 

const BasicInfoBackground = {
  dark: [
    Anthill_BG,
    Arcana_BG,
    El_Nath_BG,
    Kerning_City_BG,
    Limen_BG,
    Magatia_BG,
    Papulatus_BG,
    Sword_Grave_BG,
  ],
  light: [
    Aquarium_BG,
    Esfera_BG,
    Henesys_BG,
    Lien_BG,
    Lith_Harbor_BG,
    Ludibrium_BG,
    Mu_Lung_BG,
    Orbis_BG,
  ]
}


export default BasicInfoBackground