import Icon1 from "../../../assets/worldIcon/노바.png";
import Icon2 from "../../../assets/worldIcon/레드.png";
import Icon3 from "../../../assets/worldIcon/루나.png";
import Icon4 from "../../../assets/worldIcon/리부트.png";
import Icon5 from "../../../assets/worldIcon/버닝.png";
import Icon6 from "../../../assets/worldIcon/베라.png";
import Icon7 from "../../../assets/worldIcon/스카니아.png";
import Icon8 from "../../../assets/worldIcon/아케인.png";
import Icon9 from "../../../assets/worldIcon/엘리시움.png";
import Icon10 from "../../../assets/worldIcon/오로라.png";
import Icon11 from "../../../assets/worldIcon/유니온.png";
import Icon12 from "../../../assets/worldIcon/이노시스.png";
import Icon13 from "../../../assets/worldIcon/제니스.png";
import Icon14 from "../../../assets/worldIcon/크로아.png";

const WorldIcons = {
  노바: Icon1,
  레드: Icon2,
  루나: Icon3,
  리부트: Icon4,
  리부트2: Icon4,
  버닝: Icon5,
  버닝2: Icon5,
  버닝3: Icon5,
  베라: Icon6,
  스카니아: Icon7,
  아케인: Icon8,
  엘리시움: Icon9,
  오로라: Icon10,
  유니온: Icon11,
  이노시스: Icon12,
  제니스: Icon13,
  크로아: Icon14,
};

export default WorldIcons;
